import logo from './assets/img/logo.png';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="purzey-link">Purzey is currently available in Pakistan</p>
        <a
          className="purzey-link"
          href="https://purzey.pk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://www.countryflags.io/pk/flat/64.png" alt="flag"/>
          <p>PURZEY.PK</p>
        </a>
      </header>
    </div>
  );
}

export default App;
